import React, { LabelHTMLAttributes } from 'react'

import * as styles from './Input.styles'

export interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  text: string
  error?: string | null
  iconClassName?: string
  dataErrorTestId?: string
  endElement?: React.ReactNode
}

export const Label: React.FC<LabelProps> = ({
  iconClassName,
  text,
  error,
  dataErrorTestId,
  endElement,
  ...other
}) => {
  return (
    <div css={styles.metaContainer}>
      <label css={styles.label} {...other}>
        {iconClassName && <i css={styles.icon} className={iconClassName} />}
        <span>{text}</span>
      </label>

      {error && (
        <span data-testid={dataErrorTestId} css={styles.error}>
          {error}
        </span>
      )}
      {!error && endElement}
    </div>
  )
}
