import { css } from '@emotion/react'
import { mTheme } from '../../../style/themes'

export const rootContainer: Style = css({
  padding: 0,
  margin: 0,
  lineHeight: 1,

  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
  width: 'fit-content',
})

export const fullWidth = css({
  width: '100%',
})

export const metaContainer: Style = css({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'baseline',
})

export const label: Style = css({
  color: mTheme.fontColor.plain.primary,
  fontSize: mTheme.fontSize.S,
  paddingBottom: mTheme.size.XS,
  marginLeft: mTheme.size.XS,
  textWrap: 'nowrap',
})

export const disabled: Style = css({
  opacity: 0.6,
})

export const inputField: StyleWithOptions<boolean> =
  (hasError) =>
  ({ color, fontColor, borderColor, backgroundColor, size, radius }) => ({
    fontSize: mTheme.fontSize.M,
    margin: 0,
    padding: size.S,
    paddingTop: size.XS,
    paddingBottom: size.XS,
    minHeight: size.M,
    boxShadow: 'none',
    backgroundColor: backgroundColor.primary,
    borderRadius: radius.primary,
    border: `2px solid ${hasError ? color.alert : borderColor.secondary}`,
    color: fontColor.plain.primary,
    '::placeholder': {
      color: fontColor.disabled,
      opacity: 0.5,
    },
    '&:disabled': {
      opacity: 0.4,
    },
  })

export const icon: Style = css({
  marginRight: mTheme.size.XXS,
})

export const error: Style = css({
  fontSize: mTheme.fontSize.S,
  margin: `0 ${mTheme.size.S}`,
  color: mTheme.color.alert,
  borderTopLeftRadius: mTheme.radius.secondary,
  borderTopRightRadius: mTheme.radius.secondary,
})
